<template>
  <div class="floorPageSheet">
    <div style="margin-top: 90px"></div>
    <div class="sheetTitle">{{$t(`supportCountry[0]`)}}</div>
    <div class="paddingSheet">
      <div class="chaxun">
        <div class="selectBox">
          <span class="selectTitle">{{$t(`supportCountry[1]`)}}</span>
          <el-select v-model="countryChoosed" :placeholder="$t(`supportCountry[2]`)" class="cover-select el-select-long-officialt" multiple filterable collapse-tags>
            <el-option v-for="(item, index) in countryStatisicList" :key="index"
                       :label="item.tmDataZhName + '（' + item.counKey + '）'" :value="item.tmDataZhName"></el-option>
          </el-select>
        </div>

        <div class="search-right">
          <el-button  type="primary" @click="searchFn" style="margin-right: 10px">{{$t(`supportCountry[3]`)}}</el-button>
          <el-button class="el-long-white-button" @click="resetFn">{{$t(`supportCountry[4]`)}}</el-button>
        </div>
      </div>
      <div class="tableOne">
        <table>
          <tr>
            <th>{{$t(`supportCountry[1]`)}}</th>
            <th>{{$t(`supportCountry[5]`)}}</th>
            <th>{{$t(`supportCountry[6]`)}}</th>
            <th>{{$t(`supportCountry[7]`)}}</th>
          </tr>
          <tr v-for="item in countryStatisicList" :key="item.index" v-show="showAllFlag">
            <td>{{ item.tmDataZhName + '（' + item.counKey + '）' }}</td>
            <td>{{ item.lastUpdateDate }}</td>
            <td>{{ item.appBeginDate }}</td>
            <td>{{ item.appEndDate }}</td>
          </tr>
          <tr v-for="(item, index) in checkedCountry" :key="index" v-show="!showAllFlag">
            <td>{{ item.tmDataZhName + '（' + item.counKey + '）' }}</td>
            <td>{{ item.lastUpdateDate }}</td>
            <td>{{ item.appBeginDate }}</td>
            <td>{{ item.appEndDate }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { supportCountry } from "@/request/combinedQuery";

   // 亚洲
  const cityOptions = [
    {
      city:'中国大陆',
      cityEn: 'Mainland China',
      code:'CN',
      ingSrc:'/images/national/zgdl.jpg'
    },
    {
      city:'中国香港',
      cityEn: 'Hong Kong,China',
      code:'HK',
      ingSrc:'/images/national/zgxg.jpg'
    },
    {
      city:'印度尼西亚',
      cityEn: 'Indonesia',
      code:'ID',
      ingSrc:'/images/national/ydnxy.jpg'
    },
    {
      city:'印度',
      cityEn: 'India',
      code:'IN',
      ingSrc:'/images/national/yd.jpg'
    },
    {
      city:'日本',
      cityEn: 'Japan',
      code:'JP',
      ingSrc:'/images/national/rb.jpg'
    },
    {
      city:'土耳其',
      cityEn: 'Turkey',
      code:'TR',
      ingSrc:'/images/national/trq.jpg'
    },
    {
      city:'韩国',
      cityEn: 'Korea',
      code:'KR',
      ingSrc:'/images/national/hg.jpg'
    },
    {
      city:'中国澳门',
      cityEn: 'Macao,China',
      code:'MO',
      ingSrc:'/images/national/am.jpg'
    },
    {
      city:'马来西亚',
      cityEn: 'Malaysia',
      code:'MY',
      ingSrc:'/images/national/mlxy.jpg'
    },
    {
      city:'菲律宾',
      cityEn: 'Philippines',
      code:'PH',
      ingSrc:'/images/national/flb.jpg'
    },
    {
      city:'新加坡',
      cityEn: 'Singapore',
      code:'SG',
      ingSrc:'/images/national/xjp.jpg'
    },
    {
      city:'中国台湾',
      cityEn: 'Taiwan Province of China',
      code:'TW',
      ingSrc:'/images/national/zgtw.png'
    },
    {
      city:'泰国',
      cityEn: 'Thailand',
      code:'TH',
      ingSrc:'/images/national/tgs.jpg'
    },
    {
      city:'阿拉伯联合酋长国',
      cityEn: 'United Arab Emirates',
      code:'AE',
      ingSrc:'/images/national/alq.jpg'
    }
  ]
  // 欧洲
  const city = [
    {
      city:'奥地利',
      cityEn: 'Austria',
      code:'AT',
      ingSrc:'/images/national/adl.JPG'
    },
    {
      city:'瑞士',
      cityEn: 'Switzerland',
      code:'CH',
      ingSrc:'/images/national/rs.jpg'
    },
    {
      city:'德国',
      cityEn: 'Germany',
      code:'DE',
      ingSrc:'/images/national/dg.jpg'
    },
    {
      city:'丹麦',
      cityEn: 'Denmark',
      code:'DK',
      ingSrc:'/images/national/dm.jpg'
    },
    {
      city:'挪威',
      cityEn: 'Norway',
      code: 'NO',
      ingSrc:'/images/national/nw.jpg'
    },
    {
      city:'西班牙',
      cityEn: 'Spain',
      code:'ES',
      ingSrc:'/images/national/xby.jpg'
    },
    {
      city:'欧盟',
      cityEn: 'EUIPO',
      code:'EU',
      ingSrc:'/images/national/om.jpg'
    },
    {
      city:'芬兰',
      cityEn: 'Finland',
      code:'FI',
      ingSrc:'/images/national/fl.jpg'
    },
    {
      city:'法国',
      cityEn: 'France',
      code:'FR',
      ingSrc:'/images/national/fg.jpg'
    },
    {
      city:'英国',
      cityEn: 'Grate Britain',
      code:'GB',
      ingSrc:'/images/national/yg.jpg'
    },
    {
      city:'葡萄牙',
      cityEn: 'Portugal',
      code:'PT',
      ingSrc:'/images/national/pty.jpg'
    },
    {
      city:'希腊',
      cityEn: 'Greece',
      code:'GR',
      ingSrc:'/images/national/xl.jpg'
    },
    {
      city:'匈牙利',
      cityEn: 'Hungary',
      code:'HU',
      ingSrc:'/images/national/xyl.jpg'
    },
    {
      city:'爱尔兰',
      cityEn: 'Ireland',
      code:'IE',
      ingSrc:'/images/national/ael.jpg'
    },
    {
      city:'冰岛',
      cityEn: 'Iceland',
      code:'IS',
      ingSrc:'/images/national/bd.jpg'
    },
    {
      city:'意大利',
      cityEn: 'Italy',
      code:'IT',
      ingSrc:'/images/national/ydl.jpg'
    },
    {
      city:'瑞典',
      cityEn: 'Sweden',
      code:'SE',
      ingSrc:'/images/national/rd.jpg'
    },
    {
      city:'俄罗斯',
      cityEn: 'Russia',
      code:'RU',
      ingSrc:'/images/national/elss.jpg'
    },
    {
      city:'比荷卢经济联盟',
      cityEn: 'Benelux',
      code:'BX',
      ingSrc:'/images/national/bhl.png'
    }
  ]
  // 北美洲
  const cityOptionsd = [
    {
      city:'加拿大',
      cityEn: 'Canada',
      code:'CA',
      ingSrc:'/images/national/jnd.jpg'
    },
    {
      city:'墨西哥',
      cityEn: 'Mexico',
      code:'MX',
      ingSrc:'/images/national/mxg.jpg'
    },
    {
      city:'美国',
      cityEn: 'USPTO',
      code:'US',
      ingSrc:'/images/national/mg.jpg'
    },
  ]
  // 南美洲
  const cityOptionsds = [
    {
      city:'阿根廷',
      cityEn: 'Argentina',
      code:'AR',
      ingSrc:'/images/national/agt.jpg'
    },
    {
      city:'巴西',
      cityEn: 'Brazil',
      code:'BR',
      ingSrc:'/images/national/bx.jpg'
    },
    {
      city:'智利',
      cityEn: 'Chile',
      code:'CL',
      ingSrc:'/images/national/zl.jpg'
    },
  ]
  // 非洲
  const cityOpts = [
    {
      city:'南非',
      cityEn: 'South Africa',
      code:'ZA',
      ingSrc:'/images/national/nf.jpg'
    }
  ]
  // 大洋洲
  const cityds = [
    {
      city:'澳大利亚',
      cityEn: 'Australia',
      code:'AU',
      ingSrc:'/images/national/adly.jpg'
    },
    {
      city:'新西兰',
      cityEn: 'New Zealand',
      code:'NZ',
      ingSrc:'/images/national/xxl.jpg'
    }
  ]
  //知识产权组织
  const propertyOrganization = [
    {
      city:'世界知识产权组织国际局',
      cityEn: 'WIPO',
      code:'WO',
      ingSrc:'/images/national/zscq.jpg'
    }
  ]

  export default {
    data() {
      return {
        countryChoosed: [],
        showAllFlag: true,
        checkedCountry: [],
        countryStatisicList: []
      }
    },
    filters: {
      isCanGraphQuery(val) {
        if (val === 0) {
          return '否'
        } else {
          return '是'
        }
      },
      formatDate(val) {
        if (!val) return ''
        return moment(val).format('YYYY年MM月DD日')
      }
    },
    created() {
      this.datesetGetListFn()
    },
    mounted() {},
    methods: {
      resetFn() {
        this.countryChoosed = [];
        this.showAllFlag = true;
      },
      searchFn() {
        if (this.countryChoosed.length == 0) {
          this.showAllFlag = true;
        } else {
          this.showAllFlag = false;
          this.checkedCountry = [];
          this.countryChoosed.forEach(items => {
            this.countryStatisicList.forEach(item => {
              if (item.tmDataZhName === items) {
                this.checkedCountry.push(item);
              }
            })
          })
        }
      },
      datesetGetListFn(){
        supportCountry()
          .then((data) =>{
            if(data.length != 0){
              data.map(item => {
                this.allCountry.map(items => {
                  if (items.code == item.counKey) {
                    item.tmDataZhName = items.city
                  }
                })

                if(item.isTextQuerySelect != 0) {
                  if(item.tmDataZhName == '世界知识产权组织国际局') {
                    item.tmDataZhName = '马德里'
                  }

                }
                this.countryStatisicList.push(item)
              })


              this.countryStatisicList.sort((a, b) => {
                if (a.country > b.country) {
                  return 1
                } else if (a.country < b.country) {
                  return -1
                } else {
                  return 0
                }
              })
            }
          }).catch((error) =>{})
      },
    },
    computed: {
      allCountry(){
        return  cityOptions.concat(city,cityOptionsd,cityOptionsds,cityOpts,cityds,propertyOrganization)
      }
    },
  }
</script>

<style scoped lang="less">
  .el-long-white-button:hover{
    background: #193dbe;
    color: #FFFFFF;
  }

  .el-select-long-officialt {
    width: 260px;
    background: #fff;
  }

  .floorPageSheet {
    width: 98%;
    background: white;
    margin: 15px auto;

    .paddingSheet {
      width: 90%;
      margin: auto;

      .selectBox {
        margin-right: 35px;
        display: inline-block;
        margin-bottom: 20px;
      }

      .search-right {
        display: inline-block;
        vertical-align: 1px;
      }
    }

    .sheetTitle {
      text-align: center;
      padding-top: 13px;
      padding-bottom: 13px;
      margin-bottom: 29px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #e5e6eb;
    }

    .selectBox {
      .selectTitle {
        margin-right: 15px;

        .el-select-long-officialt {
          width: 180px;
        }
      }
    }

    .promptBox {
      div {
        margin-top: 30px;
        margin-bottom: 10px;
      }

      .promptImg img {
        width: 15px;
        height: 15px;
        vertical-align: middle;
      }

      .promptText {
        color: #d01027;
        vertical-align: middle;
      }
    }

    .tableOne {
      .fyBlock {
        padding-bottom: 5%;
        padding-top: 3%;
        text-align: center;
      }
    }

    .tableOne table {
      width: 100%;
      border: 1px solid #e4e4e4;
      text-align: center;

      tr th {
        height: 50px;
        background: #eeeeee;
        color: #888;
        font-size: 14px;
      }

      tr {
        td {
          height: 45px;
          border: 1px solid #e4e4e4;
          color: #222;
          font-size: 13px;
        }

        th:nth-of-type(1) {
          width: 20%;
          border-radius: 4px 4px 0px 0px;
        }

        th:nth-of-type(2) {
          width: 20%;
        }

        th:nth-of-type(3) {
          width: 20%;
        }

        th:nth-of-type(4) {
          width: 20%;
        }

        th:nth-of-type(5) {
          width: 20%;
        }

      }
    }
  }
</style>
